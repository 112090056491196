import Footer from './Footer/Footer';
import FooterBigIcons from './Footer/FooterBigIcons/FooterBigIcons';
import NewsletterForm from './Footer/NewsletterForm/NewsletterForm';
import Header from './Header/Header';
import NotificationBars from './NotificationBars/NotificationBars';
import Webline from './Webline/Webline';
import SeoMeta from 'components/Basic/Head/SeoMeta';
import Adverts from 'components/Blocks/Adverts/Adverts';
import LastVisitedProducts from 'components/Blocks/Product/LastVisitedProducts/LastVisitedProducts';
import { SkeletonManager } from 'components/Blocks/SkeletonManager/SkeletonManager';
import { useMediaMin } from 'hooks/ui/useMediaMin';
import { useSessionStore } from 'store/useSessionStore';

type LayoutProps = {
    title?: string | null;
    description?: string | null;
    showLastVisitedProducts?: boolean;
    isFetchingData?: boolean;
};

/**
 * Basic page layout for common pages
 */
const CommonLayout: FC<LayoutProps> = ({ children, title, description, showLastVisitedProducts, isFetchingData }) => {
    const isDesktop = useMediaMin('vl');
    const { isPageLoading, destinationOfRedirect } = useSessionStore((s) => s);

    return (
        <>
            <SeoMeta title={title} description={description} />
            <NotificationBars />
            <Header simple={destinationOfRedirect === 'transport-and-payment'} />
            <Adverts positionName="header" withGapBottom withWebline />
            <SkeletonManager isFetchingData={isFetchingData}>{children}</SkeletonManager>
            {showLastVisitedProducts && !isPageLoading && !isFetchingData && <LastVisitedProducts />}
            <Webline>
                <FooterBigIcons />
            </Webline>
            {(isDesktop || isDesktop === undefined) && (
                <Webline type="light" className="hidden vl:block">
                    <NewsletterForm />
                </Webline>
            )}
            <Webline type="light">
                <Footer />
            </Webline>
        </>
    );
};

/* @component */
export default CommonLayout;
