import { ArticleDetailType } from './article';
import { BlogArticleDetailType } from './blogArticle';
import { BlogCategoryDetailType } from './blogCategory';
import { BrandDetailType } from './brand';
import { CategoryDetailType } from './category';
import { FlagDetailType } from './flag';
import { ProductDetailType, VariantDetailType } from './product';
import { StoreDetailType } from './store';

export type FriendlyUrlPageType =
    | ProductDetailType
    | VariantDetailType
    | CategoryDetailType
    | StoreDetailType
    | ArticleDetailType
    | BlogArticleDetailType
    | BlogCategoryDetailType
    | BrandDetailType
    | FlagDetailType;

export const FriendlyPagesTypes = {
    article: 'front_article_detail',
    blogArticle: 'front_blogarticle_detail',
    blogCategory: 'front_blogcategory_detail',
    brand: 'front_brand_detail',
    category: 'front_product_list',
    product: 'front_product_detail',
    store: 'front_stores_detail',
    flag: 'front_flag_detail',
    seo_category: 'front_category_seo',
} as const;

export const FriendlyPagesDestinations = {
    article: '/articles/[articleSlug]',
    blogArticle: '/blogArticles/[blogArticleSlug]',
    blogCategory: '/blogCategories/[blogCategorySlug]',
    brand: '/brands/[brandSlug]',
    category: '/categories/[categorySlug]',
    product: '/products/[productSlug]',
    store: '/stores/[storeSlug]',
    flag: '/flags/[flagSlug]',
    seo_category: '/categories/[categorySlug]',
} as const;

export type FriendlyPagesTypesKeys = keyof typeof FriendlyPagesTypes;

export type FriendlyPageTypesValue = typeof FriendlyPagesTypes[FriendlyPagesTypesKeys];
