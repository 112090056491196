import isEqual from 'lodash/isEqual';
import { useEffect, useRef } from 'react';

export const useDeepCompare = <T,>(value: T): T => {
    const previousValueRef = useRef<T>(value);

    useEffect(() => {
        if (!isEqual(previousValueRef.current, value)) {
            previousValueRef.current = value;
        }
    }, [value]);

    return previousValueRef.current;
};
