import { getFirstImage } from 'connectors/image/Image';
import { mapListedProductConnectionPreviewType, mapListedProductType } from 'connectors/products/Products';
import {
    CategoryDetailFragmentApi,
    ListedCategoryConnectionFragmentApi,
    ListedCategoryFragmentApi,
    SimpleCategoryConnectionFragmentApi,
    SimpleCategoryFragmentApi,
} from 'graphql/generated';
import {
    CategoryDetailType,
    ListedCategoryConnectionType,
    ListedCategoryType,
    SimpleCategoryConnectionType,
    SimpleCategoryType,
} from 'types/category';

export const mapCategoryDetailData = (
    apiCategoryDetailData: CategoryDetailFragmentApi,
    currencyCode: string,
): CategoryDetailType => {
    return {
        ...apiCategoryDetailData,
        __typename: 'Category',
        productConnection: mapListedProductConnectionPreviewType(apiCategoryDetailData.products),
        children: apiCategoryDetailData.children.map((child) => mapListedCategoryApiData(child)),
        linkedCategories: apiCategoryDetailData.linkedCategories.map((child) => mapListedCategoryApiData(child)),
        image: getFirstImage(apiCategoryDetailData.image),
        bestsellers: apiCategoryDetailData.bestsellers.map((item) => mapListedProductType(item, currencyCode)),
    };
};

export const mapListedCategoryApiData = (listedCategoryApiData: ListedCategoryFragmentApi): ListedCategoryType => {
    return {
        uuid: listedCategoryApiData.uuid,
        name: listedCategoryApiData.name,
        slug: listedCategoryApiData.slug,
        link: listedCategoryApiData.link,
        productsCount: listedCategoryApiData.productsCount,
        image: getFirstImage(listedCategoryApiData.images),
    };
};

export const mapSimpleCategoryConnectionApiData = (
    apiData: SimpleCategoryConnectionFragmentApi,
): SimpleCategoryConnectionType => {
    const mappedCategories = [];

    if (apiData.edges !== null) {
        for (const categoryEdge of apiData.edges) {
            if (categoryEdge?.node !== undefined && categoryEdge.node !== null) {
                mappedCategories.push(mapSimpleCategoryApiData(categoryEdge.node));
            }
        }
    }

    return { totalCount: apiData.totalCount, categories: mappedCategories };
};

const mapSimpleCategoryApiData = (apiData: SimpleCategoryFragmentApi): SimpleCategoryType => {
    return apiData;
};

export const mapListedCategoryConnectionApiData = (
    apiData: ListedCategoryConnectionFragmentApi,
): ListedCategoryConnectionType => {
    const mappedCategories = [];

    if (apiData.edges !== null) {
        for (const categoryEdge of apiData.edges) {
            if (categoryEdge?.node !== undefined && categoryEdge.node !== null) {
                mappedCategories.push(mapListedCategoryApiData(categoryEdge.node));
            }
        }
    }

    return { totalCount: apiData.totalCount, categories: mappedCategories };
};

export const mapSimpleCategories = (apiData: SimpleCategoryFragmentApi[]): SimpleCategoryType[] => {
    return apiData.map((simpleCategoryApiData) => mapSimpleCategoryApiData(simpleCategoryApiData));
};
