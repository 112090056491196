import { getStateAfterValidation } from 'components/Forms/Helpers/getStateAfterValidation';
import LabelWrapper from 'components/Forms/Lib/LabelWrapper/LabelWrapper';
import { formMasking } from 'helpers/forms/formMasking';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { HideIcon, SearchIcon, ShowIcon, SpinnerIcon } from 'public/svg/IconsSvg';
import { forwardRef, InputHTMLAttributes, useCallback, useEffect, useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { ExtractNativePropsFromDefault } from 'typeHelpers/ExtractNativePropsFromDefault';
import twMerge from 'utils/twMerge';

type NativeProps = ExtractNativePropsFromDefault<
    InputHTMLAttributes<HTMLInputElement>,
    never,
    | 'name'
    | 'id'
    | 'disabled'
    | 'style'
    | 'required'
    | 'onBlurCapture'
    | 'onChange'
    | 'onKeyPress'
    | 'onBlur'
    | 'onFocus'
    | 'autoComplete'
    | 'className'
    | 'inputMode'
>;

export type TextInputProps = NativeProps & {
    label: string | JSX.Element;
    type: 'text' | 'password' | 'email' | 'tel' | 'search' | 'number';
    hasError?: boolean;
    isTouched?: boolean;
    inputSize?: 'small';
    placeholderType?: 'static';
    variant?: 'searchInHeader';
    markSuccessfulWhenValid?: boolean;
    fieldRef?: ControllerRenderProps<any, any>;
    isSearchButtonDisabled?: boolean;
    value?: number | string;
    isLoading?: boolean;
    className?: React.ComponentProps<'div'>['className'];
};

const TextInput = forwardRef<any, TextInputProps>(
    (
        {
            hasError,
            isTouched,
            markSuccessfulWhenValid,
            label,
            fieldRef,
            id,
            isSearchButtonDisabled,
            type,
            variant,
            required,
            placeholderType,
            onChange,
            value,
            onBlur,
            name,
            isLoading,
            inputSize,
            className,
            ...restProps
        },
        ref,
    ) => {
        const [inputState, setInputState] = useState<'success' | 'error'>();
        const [inputType, setInputType] = useState<'text' | 'password' | 'email' | 'tel' | 'search' | 'number'>();
        const domainConfig = useDomainConfig();

        const togglePasswordVisibilityHandler = useCallback(
            () => setInputType((prev) => (prev === 'text' ? 'password' : 'text')),
            [],
        );

        useEffect(() => {
            setInputState(getStateAfterValidation(hasError, isTouched, markSuccessfulWhenValid));
        }, [hasError, isTouched, markSuccessfulWhenValid]);

        const isEmpty =
            (value === undefined || value === '') && (fieldRef?.value === undefined || fieldRef.value === '');

        return (
            <LabelWrapper
                htmlFor={id}
                inputType="text-input"
                label={label}
                placeholderType={placeholderType}
                required={required}
                isEmpty={isEmpty}
            >
                <input
                    id={id}
                    name={fieldRef?.name ?? name}
                    onBlur={fieldRef?.onBlur ?? onBlur}
                    onChange={fieldRef?.onChange ?? onChange}
                    placeholder={typeof label === 'string' ? label : ' '}
                    ref={fieldRef?.ref ?? ref}
                    required={required}
                    type={inputType ?? type}
                    value={formMasking(fieldRef?.value ?? value, domainConfig.defaultLocale, fieldRef?.name ?? name)}
                    className={twMerge(
                        'peer box-border w-full rounded-lg border border-solid bg-white font-normal text-primary w-appearance-none [-moz-appearance:textfield]',
                        variant === 'searchInHeader' ? 'border-white pr-12 pl-4' : 'border-border',
                        placeholderType === 'static' ? 'px-2' : 'px-2 pt-5',
                        inputSize === 'small' ? 'h-12 text-small' : 'h-[50px] text-body',
                        placeholderType === 'static'
                            ? 'placeholder:text-grayDarker placeholder:opacity-100'
                            : 'placeholder:text-transparent',
                        inputState === 'error' && 'border-red bg-white shadow-redShadow',
                        inputState === 'success' && 'rounded-md border-green shadow-green',
                        'disabled/read-only:pointer-events-none disabled/read-only:cursor-no-drop disabled/read-only:opacity-50',
                        type === 'password' && 'text-h4 text-grayDarker focus-visible:text-primary',
                        type === 'password' &&
                            'webkit-outer-spin-button:m-0 webkit-outer-spin-button:w-appearance-none webkit-inner-spin-button:m-0 webkit-inner-spin-button:w-appearance-none',
                        'focus:border-borderFocus focus:outline focus:outline-[3px] focus:outline-outlineFocus',
                        'webkit-autofill:!bg-white webkit-autofill:!shadow-input webkit-autofill:hover:!bg-white webkit-autofill:hover:!shadow-input webkit-autofill:focus:!bg-white webkit-autofill:focus:!shadow-input internal-autofill-selected:!bg-white internal-autofill-selected:!shadow-input',
                        className,
                    )}
                    {...restProps}
                />
                {type === 'password' && (
                    <div
                        className="absolute top-2/4 right-4 flex h-6 w-6 -translate-y-2/4 cursor-pointer content-center items-center"
                        onClick={togglePasswordVisibilityHandler}
                    >
                        {inputType === 'text' ? (
                            <HideIcon className="h-4 w-4 text-grayDarker" />
                        ) : (
                            <ShowIcon className="h-4 w-4 text-grayDarker" />
                        )}
                    </div>
                )}
                {variant === 'searchInHeader' && !isLoading && (
                    <button
                        className="absolute top-3 right-4 cursor-pointer border-none bg-transparent"
                        type="submit"
                        disabled={isSearchButtonDisabled}
                    >
                        <SearchIcon className="h-5 w-5" />
                    </button>
                )}
                {isLoading && (
                    <div className="absolute right-3 top-[calc(50%-1rem)] flex h-8 w-8 items-center justify-center">
                        <SpinnerIcon className="h-7 w-7" />
                    </div>
                )}
            </LabelWrapper>
        );
    },
);

TextInput.displayName = 'TextInput';

export default TextInput;
