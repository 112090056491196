import { createDomainSlice, DomainSlice } from './slices/createDomainSlice';
import { createGeneralSlice, GeneralSliceType } from './slices/createGeneralSlice';
import { createSelectPickupPointSlice, SelectPickupPointSlice } from './slices/createSelectPickupPointSlice';
import { createSeoCategorySlice, SeoCategorySlice } from './slices/createSeoCategorySlice';
import { create } from 'zustand';

type SessionStore = GeneralSliceType & DomainSlice & SelectPickupPointSlice & SeoCategorySlice;

export const useSessionStore = create<SessionStore>()((...store) => ({
    ...createDomainSlice(...store),
    ...createGeneralSlice(...store),
    ...createSelectPickupPointSlice(...store),
    ...createSeoCategorySlice(...store),
}));
