import { getStringWithoutTrailingSlash } from 'helpers/parsing/stringWithoutSlash';
import { getUrlQueriesWithoutDynamicPageQueries } from 'helpers/parsing/urlParsing';
import { getUrlWithoutGetParameters } from 'helpers/parsing/urlParsing';
import { INTERNAL_QUERY_PARAMETERS } from 'helpers/queryParams/queryParamNames';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { UrlQueries } from 'types/urlQueries';

type CanonicalProps = {
    url: string;
};

export const Canonical: FC<CanonicalProps> = ({ url }) => {
    const router = useRouter();
    const urlOverwrite = useMemo<string | null>(() => {
        const newQueryOverwrite: Record<string, string> = {};
        const queryWithoutAllParameter = getUrlQueriesWithoutDynamicPageQueries(router.query);

        for (const queryParam in queryWithoutAllParameter) {
            if ((INTERNAL_QUERY_PARAMETERS as string[]).includes(queryParam)) {
                const queryParamValue = queryWithoutAllParameter[queryParam as keyof UrlQueries]?.toString();

                if (queryParamValue) {
                    newQueryOverwrite[queryParam] = queryParamValue;
                }
            }
        }

        if (JSON.stringify(newQueryOverwrite) === JSON.stringify(queryWithoutAllParameter)) {
            return null;
        }

        const queryParams = new URLSearchParams(newQueryOverwrite).toString();
        const urlOverwriteWithoutQueryParams = `${getStringWithoutTrailingSlash(url)}${getUrlWithoutGetParameters(
            router.asPath,
        )}`;

        if (queryParams.length === 0) {
            return urlOverwriteWithoutQueryParams;
        }

        return `${urlOverwriteWithoutQueryParams}?${queryParams}`;
    }, [router, url]);

    if (urlOverwrite === null) {
        return null;
    }

    return (
        <Head>
            <link rel="canonical" href={urlOverwrite} />
        </Head>
    );
};
