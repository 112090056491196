import { ApplicationErrors, ApplicationErrorsType } from 'helpers/errors/applicationErrors';
import { getErrorMessage, hasErrorMessage } from 'helpers/errors/errorMessageMapper';
import { ApplicationIgnoredErrors } from 'helpers/errors/ignoredErrors';
import { Translate } from 'next-translate';
import { ParsedErrors, ValidationErrors } from 'types/error';
import { CombinedError } from 'urql';

export const getUserFriendlyErrors = (originalError: CombinedError, t: Translate): ParsedErrors => {
    const errors: ParsedErrors = {};

    if (originalError.networkError) {
        errors.networkError = t('Could not connect to server. Check your network.') as string;
    } else if (originalError.graphQLErrors.length > 0) {
        for (const error of originalError.graphQLErrors) {
            const errorUserCode = error.extensions.userCode as ApplicationErrorsType | null | undefined;
            if (Object.prototype.hasOwnProperty.call(error.extensions, 'validation')) {
                const mappedValidationErrors: ValidationErrors = {};

                const errorExtensions = error.extensions.validation as {
                    validation: ValidationErrors | undefined;
                };
                for (const errorName in errorExtensions) {
                    const newErrorName = errorName.replace('input.', '');
                    const validationError = errorExtensions[errorName as keyof typeof errorExtensions]?.[0];

                    if (validationError?.code?.startsWith('AIRME_')) {
                        validationError.message = t(validationError.message);
                    }

                    if (validationError) {
                        mappedValidationErrors[newErrorName] = validationError;
                    }
                }

                errors.userError = { validation: mappedValidationErrors };
                continue;
            }

            if (errorUserCode && ApplicationIgnoredErrors.includes(errorUserCode)) {
                continue;
            }

            if (error.extensions.code === 'loyalty-club-points-overdraft') {
                errors.applicationError = {
                    type: ApplicationErrors['loyalty-club-points-overdraft'],
                    message: error.message,
                };
                continue;
            }

            if (
                error.extensions.category === 'user' &&
                error.message === 'Selected store is disabled for reservation in cart.'
            ) {
                errors.applicationError = {
                    type: ApplicationErrors['reservations-disabled-at-place'],
                    message: t('Reservations are disabled at this place'),
                };
                continue;
            }

            if (errorUserCode && hasErrorMessage(errorUserCode, t)) {
                errors.applicationError = {
                    type: errorUserCode,
                    message: getErrorMessage(errorUserCode, t),
                };
                continue;
            }

            if (error.extensions.category === 'token') {
                // this error means that there is a response with code 401 and refresh tokens will be called
                // we don't want to show error here
                continue;
            }
            errors.applicationError = { type: ApplicationErrors.default, message: t('Unknown error.') };
        }
    } else {
        errors.applicationError = { type: ApplicationErrors.default, message: t('Unknown error.') };
    }

    return errors;
};
