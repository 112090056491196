import { STATIC_REWRITE_PATHS, StaticRewritePathKeyType } from 'config/staticRewritePaths';
import { getUrlWithoutGetParameters } from 'helpers/parsing/urlParsing';
import { GetServerSidePropsContext } from 'next';
import { NextPageContext } from 'next';

type Url = string | { url: string; param: string | undefined | null };

function getInternationalizedStaticUrl(url: Url, domainUrl: string) {
    const urlsOnDomain = STATIC_REWRITE_PATHS[domainUrl];

    if (typeof url === 'string') {
        const result = urlsOnDomain[url as StaticRewritePathKeyType];

        return typeof result !== 'undefined' ? result : '';
    }

    const staticUrlTemplate = urlsOnDomain[url.url as StaticRewritePathKeyType];
    const staticPart = staticUrlTemplate?.split(':')[0];

    return (staticPart ?? '') + (url.param ?? '');
}

export const getInternationalizedStaticUrls = (urls: Url[], domainUrl: string): string[] => {
    return urls.map((url) => getInternationalizedStaticUrl(url, domainUrl));
};

export function getServerSideInternationalizedStaticUrl(
    context: GetServerSidePropsContext | NextPageContext,
    domainUrl: string,
): { trimmedUrlWithoutQueryParams: string; queryParams: string | null } {
    if (!('resolvedUrl' in context)) {
        return { trimmedUrlWithoutQueryParams: '/', queryParams: null };
    }

    const trimmedUrlWithoutQueryParams = getUrlWithoutGetParameters(context.resolvedUrl);
    const result = getInternationalizedStaticUrl(trimmedUrlWithoutQueryParams, domainUrl);
    const queryParams = context.resolvedUrl.split('?')[1];

    return {
        trimmedUrlWithoutQueryParams: result !== '' ? result : trimmedUrlWithoutQueryParams,
        queryParams: queryParams ? `?${queryParams}` : null,
    };
}
