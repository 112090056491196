import { Canonical } from 'components/Basic/Head/Canonical/Canonical';
import { SupportChat } from 'components/Blocks/SupportChat/SupportChat';
import CommonLayout from 'components/Layout/CommonLayout';
import { Error503 } from 'components/Pages/ErrorPage/503/Error503';
import PageContentProvider from 'context/PageContentProvider/PageContentProvider';
import ShopsysGlobalProvider from 'context/ShopsysGlobalProvider/ShopsysGlobalProvider';
import { extend, locale } from 'dayjs';
import 'dayjs/locale/cs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/hu';
import 'dayjs/locale/it';
import 'dayjs/locale/pl';
import 'dayjs/locale/pt';
import 'dayjs/locale/ru';
import 'dayjs/locale/sk';
import 'dayjs/locale/zh';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import { ServerSidePropsType } from 'helpers/InitServerSideProps';
import { isDomainLocale } from 'helpers/isDomainLocale';
import { useDomainConfig } from 'hooks/useDomainConfig';
import i18nConfig from 'i18n';
import appWithI18n from 'next-translate/appWithI18n';
import { AppProps as NextAppProps } from 'next/app';
import getConfig from 'next/config';
import { NextComponentType, NextPageContext } from 'next/dist/shared/lib/utils';
import dynamic from 'next/dynamic';
import { Roboto } from 'next/font/google';
import Head from 'next/head';
import { ReactElement } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import 'styles/globals.css';
import { NextPageCustomProps } from 'types/global';
import getGtmHeadScript from 'utils/Gtm/GtmHeadScript';

const UrqlWrapper = dynamic(() => import('components/Layout/UrqlWrapper').then((component) => component.UrqlWrapper));

extend(LocalizedFormat);

const robotoFont = Roboto({
    weight: ['400', '500', '700'],
    subsets: ['latin-ext', 'latin'],
    variable: '--font-roboto',
});

type ErrorProps = { err?: any };

type CustomAppComponentProps = NextComponentType<NextPageContext, any, any> & NextPageCustomProps;

type CustomNextAppProps = {
    Component: CustomAppComponentProps;
};

type AppProps = {
    pageProps: ServerSidePropsType;
} & Omit<NextAppProps<ErrorProps>, 'Component' | 'pageProps'> &
    ErrorProps &
    CustomNextAppProps;

function MyApp({ Component, pageProps, err }: AppProps): ReactElement {
    const { publicRuntimeConfig } = getConfig();
    const cdnUrl = publicRuntimeConfig.cdnUrl;
    const { defaultLocale } = useDomainConfig();
    const isCzechLocale = isDomainLocale(defaultLocale, 'cs');

    locale(defaultLocale);

    return (
        <>
            <Head>
                <meta name="apple-mobile-web-app-title" content="Tescoma" />
                <meta name="application-name" content="Tescoma" />
                <link rel="apple-touch-icon" sizes="180x180" href={`${cdnUrl}/favicons/apple-touch-icon.png`} />
                <link rel="icon" type="image/png" sizes="32x32" href={`${cdnUrl}/favicons/favicon-32x32.png`} />
                <link rel="icon" type="image/png" sizes="16x16" href={`${cdnUrl}/favicons/favicon-16x16.png`} />
                <link rel="manifest" href={`${cdnUrl}/favicons/site.webmanifest`} />
                <link rel="mask-icon" href={`${cdnUrl}/favicons/safari-pinned-tab.svg`} color="#D90000" />
                <link rel="shortcut icon" href={`${cdnUrl}/favicons/favicon.ico`} type="image/x-icon" />
                <meta name="msapplication-TileColor" content="#b91d47" />
                <meta name="theme-color" content="#b91d47" />
            </Head>
            {getGtmHeadScript()}
            {isCzechLocale && <SupportChat />}
            <style jsx global>{`
                html {
                    font-family: ${robotoFont.style.fontFamily};
                }
            `}</style>
            <Canonical url={pageProps.domainConfig.url} />
            <UrqlWrapper pageProps={pageProps}>
                <ShopsysGlobalProvider>
                    <PageContentProvider pageProps={pageProps}>
                        {Component.layout === 'plain' ? (
                            <Component {...pageProps} err={err} />
                        ) : (
                            <CommonLayout showLastVisitedProducts={Component.layout === 'withLastVisitedProducts'}>
                                {pageProps.isMaintenance ? <Error503 /> : <Component {...pageProps} err={err} />}
                            </CommonLayout>
                        )}
                    </PageContentProvider>
                </ShopsysGlobalProvider>
            </UrqlWrapper>
        </>
    );
}

// eslint-disable-next-line
// @ts-ignore
export default appWithI18n(MyApp, { ...i18nConfig });
