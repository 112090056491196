import { theme } from 'components/Theme/main';
import { mapAvailabilityData } from 'connectors/availability/Availability';
import { getFirstImage } from 'connectors/image/Image';
import { mapPageInfoApiData } from 'connectors/pageInfo/PageInfo';
import { mapProductPriceData, mapProductPricesData } from 'connectors/price/Prices';
import {
    AutocompleteProductConnectionFragmentApi,
    AutocompleteProductFragmentApi,
    ListedProductConnectionFragmentApi,
    ListedProductConnectionPreviewFragmentApi,
    ListedProductFragmentApi,
    SearchProductsQueryApi,
    SliderProductFragmentApi,
    useLastVisitedProductsQueryApi,
    useProductsByCatnumsApi,
    usePromotedProductsQueryApi,
} from 'graphql/generated';
import { useQueryError } from 'hooks/graphQl/UseQueryError';
import { useDeepCompare } from 'hooks/helpers/useDeepCompare';
import { useDomainConfig } from 'hooks/useDomainConfig';
import { AvailabilityStatusEnum } from 'types/availability';
import { ExtendedConfiguratorProductApi } from 'types/configurator';
import {
    AutocompleteProductConnectionType,
    AutocompleteProductType,
    ListedProductConnectionPreviewType,
    ListedProductConnectionType,
    ListedProductSearchConnectionType,
    ListedProductType,
    SliderProductItemType,
} from 'types/product';

export const mapListedProductType = (apiData: ListedProductFragmentApi, currencyCode: string): ListedProductType => {
    return {
        ...apiData,
        isMainVariant: apiData.__typename === 'MainVariant',
        availability: mapAvailabilityData(apiData.availability),
        availabilityStatus: apiData.availability.status as AvailabilityStatusEnum,
        image: getFirstImage(apiData.images),
        price: mapProductPriceData(apiData.price, currencyCode),
        prices: mapProductPricesData(apiData.prices, currencyCode),
        isSellingDenied: apiData.isSellingDenied,
        colorVariants: apiData.colorVariants.map((variant) => ({
            ...variant,
            value: variant.value === null ? theme.color.gray : variant.value,
            colorImage: getFirstImage(variant.parameterValueImages ?? null),
        })),
        categoryNames: apiData.categories.map((category) => category.name),
        isElectronicGiftVoucher: apiData.isElectronicGiftVoucher,
    };
};

export const usePromotedProducts = (): SliderProductItemType[] | undefined => {
    const { currencyCode } = useDomainConfig();
    const [{ data, error }] = usePromotedProductsQueryApi();
    useQueryError(error);

    const apiData = data?.promotedProducts;

    return useDeepCompare(apiData === undefined ? undefined : mapSliderProductApiData(apiData, currencyCode));
};

export const useLastVisitedProducts = (lastVisitedProductCatalogNumbers: string[]) => {
    const { currencyCode } = useDomainConfig();

    const [{ data: lastVisitedProductsData, error, fetching: lastVisitedProductsFetching }] =
        useLastVisitedProductsQueryApi({
            variables: { catnums: lastVisitedProductCatalogNumbers },
            pause: !lastVisitedProductCatalogNumbers.length,
        });

    useQueryError(error);

    if (!lastVisitedProductsData?.productsByCatnums) {
        return { lastVisitedProducts: null, lastVisitedProductsFetching: false };
    }

    const sortedLastVisitedProducts: SliderProductFragmentApi[] = [];
    lastVisitedProductCatalogNumbers.forEach((lastVisitedProductsCatalogNumber) => {
        const product = lastVisitedProductsData.productsByCatnums.find(
            (product) => lastVisitedProductsCatalogNumber === product.catalogNumber,
        );

        if (product !== undefined) {
            sortedLastVisitedProducts.push(product);
        }
    });

    return {
        lastVisitedProducts: mapSliderProductApiData(sortedLastVisitedProducts, currencyCode),
        lastVisitedProductsFetching,
    };
};

export const useProductsByCatnums = (catnums: string[]): SliderProductItemType[] | null => {
    const { currencyCode } = useDomainConfig();

    const [{ data, error }] = useProductsByCatnumsApi({
        variables: { catnums },
    });

    useQueryError(error);

    if (data?.productsByCatnums === undefined) {
        return null;
    }

    return mapSliderProductApiData(data.productsByCatnums, currencyCode);
};

export const mapSliderProductApiData = (
    apiData: SliderProductFragmentApi[],
    currencyCode: string,
): SliderProductItemType[] => {
    return apiData.map((apiProduct) => {
        return {
            ...apiProduct,
            name: apiProduct.name,
            image: getFirstImage(apiProduct.images),
            price: mapProductPriceData(apiProduct.price, currencyCode),
            prices: mapProductPricesData(apiProduct.prices, currencyCode),
            isMainVariant: apiProduct.__typename === 'MainVariant',
            availability: mapAvailabilityData(apiProduct.availability),
            availabilityStatus: apiProduct.availability.status as AvailabilityStatusEnum,
            deliveryDaysFromCentralStore: apiProduct.deliveryDaysFromCentralStore,
            stockQuantity: apiProduct.stockQuantity,
            isSellingDenied: apiProduct.isSellingDenied,
            colorVariants: apiProduct.colorVariants.map((variant) => ({
                ...variant,
                value: variant.value === null ? theme.color.gray : variant.value,
                colorImage: getFirstImage(variant.parameterValueImages),
            })),
            categoryNames: apiProduct.categories.map((category) => category.name),
            isElectronicGiftVoucher: apiProduct.isElectronicGiftVoucher,
        };
    });
};

export const mapConfiguratorProductApiData = (
    apiData: ExtendedConfiguratorProductApi[],
    currencyCode: string,
): SliderProductItemType[] => {
    return apiData.map((apiProduct) => {
        return {
            ...apiProduct,
            name: apiProduct.fullName,
            image: getFirstImage(apiProduct.images),
            price: mapProductPriceData(apiProduct.price, currencyCode),
            prices: mapProductPricesData(apiProduct.prices, currencyCode),
            isMainVariant: apiProduct.__typename === 'MainVariant',
            availability: mapAvailabilityData(apiProduct.availability),
            availabilityStatus: apiProduct.availability.status as AvailabilityStatusEnum,
            deliveryDaysFromCentralStore: apiProduct.deliveryDaysFromCentralStore,
            stockQuantity: apiProduct.stockQuantity,
            isSellingDenied: apiProduct.isSellingDenied,
            colorVariants: apiProduct.colorVariants.map((variant) => ({
                ...variant,
                value: variant.value === null ? theme.color.gray : variant.value,
                colorImage: getFirstImage(variant.parameterValueImages),
            })),
            categoryNames: apiProduct.categories.map((category) => category.name),
            isElectronicGiftVoucher: apiProduct.isElectronicGiftVoucher,
        };
    });
};

export const mapListedProductConnectionPreviewType = (
    apiData: ListedProductConnectionPreviewFragmentApi,
): ListedProductConnectionPreviewType => {
    return {
        ...apiData,
        productFilterOptions: apiData.productFilterOptions,
    };
};

export const mapListedProductSearchConnectionType = (
    apiData: SearchProductsQueryApi,
    currencyCode: string,
): ListedProductSearchConnectionType => {
    return {
        ...apiData.products,
        pageInfo: mapPageInfoApiData(apiData.products.pageInfo),
        products: mapListedProductTypes(apiData.products.edges, currencyCode),
        productFilterOptions: apiData.products.productFilterOptions,
    };
};

export const mapListedProductConnectionType = (
    apiData: ListedProductConnectionFragmentApi['edges'],
    currencyCode: string,
): ListedProductConnectionType => {
    return {
        products: mapListedProductTypes(apiData, currencyCode),
    };
};

export const mapListedProductTypes = (
    apiData: ListedProductConnectionFragmentApi['edges'],
    currencyCode: string,
): ListedProductType[] => {
    const result = [];

    if (apiData !== null) {
        for (const edge of apiData) {
            if (edge?.node === undefined || edge.node === null) {
                continue;
            }
            result.push(mapListedProductType(edge.node, currencyCode));
        }
    }

    return result;
};

export const mapAutocompleteProductType = (
    apiData: AutocompleteProductFragmentApi,
    currencyCode: string,
): AutocompleteProductType => {
    return {
        ...apiData,
        image: getFirstImage(apiData.images),
        price: mapProductPriceData(apiData.price, currencyCode),
        prices: mapProductPricesData(apiData.prices, currencyCode),
        categoryNames: apiData.categories.map((category) => category.name),
        availability: mapAvailabilityData(apiData.availability),
        availabilityStatus: apiData.availability.status as AvailabilityStatusEnum,
    };
};

export const mapAutocompleteProductConnectionType = (
    apiData: AutocompleteProductConnectionFragmentApi,
    currencyCode: string,
): AutocompleteProductConnectionType => {
    return {
        ...apiData,
        pageInfo: mapPageInfoApiData(apiData.pageInfo),
        products: mapAutocompleteProductTypes(apiData, currencyCode),
        productFilterOptions: apiData.productFilterOptions,
    };
};

const mapAutocompleteProductTypes = (
    apiData: AutocompleteProductConnectionFragmentApi,
    currencyCode: string,
): AutocompleteProductType[] => {
    const result = [];

    if (apiData.edges !== null) {
        for (const edge of apiData.edges) {
            if (edge?.node === undefined || edge.node === null) {
                continue;
            }
            result.push(mapAutocompleteProductType(edge.node, currencyCode));
        }
    }

    return result;
};
