import { ProductOrderingModeEnumApi } from 'graphql/generated';

export const DEFAULT_PAGE_SIZE = 24;
export const BLOG_CATEGORY_PAGE_SIZE = 10;
export const DEFAULT_SORT = ProductOrderingModeEnumApi.PriorityApi as const;

export type DefaultProductFiltersMapType = {
    flags: Set<string>;
    brands: Set<string>;
    sort: ProductOrderingModeEnumApi;
    parameters: Map<string, Set<string>>;
};

export const getEmptyDefaultProductFiltersMap = (): DefaultProductFiltersMapType => ({
    flags: new Set(),
    brands: new Set(),
    sort: DEFAULT_SORT,
    parameters: new Map(),
});
