import { GtmListNameType } from 'types/gtm';

export const PERSOO_IDS = {
    autocomplete: 'autocomplete-container',
    searchResults: 'persoo-search-results',
    homepageCarousel: 'persoo-homepage-carousel',
    detailAlternatives: 'persoo-detail-alternatives',
    topProducts: 'top-products',
    categoryProducts: 'persoo-top-products',
    prebasketCarousel: 'persoo-prebasket-carousel',
    basketCarousel: 'persoo-basket-carousel',
};

export const PERSOO_SEARCH_QUERY_PARAMETER_NAME: string = 'searchSource';
export const PERSOO_SEARCH_QUERY_PARAMETER_VALUE: string = 'persoo';
export const PERSOO_SEARCH_TIMEOUT_SECONDS: number = 4;
// CZ B2C, SK B2C, HU B2C, PL B2C, DE B2C
export const allowedDomainsPersoo: number[] = [1, 3, 5, 7, 9];

export type AddToCartPersooDetailType = { itemId: string; listIndex: number; gtmListName: GtmListNameType };

export type WatchProductPersooDetailType = { itemId: string; itemName: string };

export const CUSTOM_EVENTS_FOR_PERSOO = {
    addToCart: 'addToBasket',
} as const;
